.App {
  display: block;
}
code {
  position: relative;
  box-sizing: border-box;
}
pre {
  position: relative;
  overflow: scroll;
  box-sizing: border-box;
  justify-content: center;
  margin: auto !important;
  width: 100%;
}
body {
  text-align: center;
  box-sizing: border-box;
  height: 100vh; 
  max-width: 100vw;
  overflow-y: scroll;
  overflow-x: hidden;
  margin: 0;
  padding: 0;
}
table {
  background-color: #fff !important;
  border-collapse: separate !important; /* This line */
}
